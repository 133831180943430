

import Pusher from 'pusher-js';
 
// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;
 
const Pushers = new Pusher('d41c23e3b146f769fda6', {
  cluster: 'us3',
});
 
export default Pushers;