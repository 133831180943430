import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "../Screens/HomePage";
import Model from "../Screens/modelsPage";
import Profile from "../Screens/profilePage";
import ModelProfile from "../Screens/modelProfilePage";

import Forgot_Pass3 from "../Screens/LoginPage/forgetpass3";
import Forgot_Pass2 from "../Screens/LoginPage/forgetpass2";
import Forgot_Pass from "../Screens/LoginPage/forgetpass";
import Login from "../Screens/LoginPage";
import AddPost from "../Screens/addPostPage";
import Packages from "../Screens/PackagesPage";
import Faqs from "../Screens/Faqs";
import Payment from "../Screens/PaymentPage";
import History from "../Screens/History";
import UserProfile from "../Screens/UserProfile";
import { Socket } from "../Screens/chatapp";
import { Chat } from "../Screens/chatapp/home";
import SignUp from "../Screens/SignUpPage";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./protectedRoute";
import AuthRoute from "./authRoute";
import socketIO from "socket.io-client";

import { UpdateProfile } from "../../src/Screens/updateprofile";
// const socket = socketIO.connect('http://localhost:4000');

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      <Routes>
        <Route
          path="/login-page"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <Forgot_Pass />
            </AuthRoute>
          }
        />
        <Route
          path="/forget-password2"
          element={
            <AuthRoute>
              <Forgot_Pass2 />
            </AuthRoute>
          }
        />
        <Route
          path="/forget-password3"
          element={
            <AuthRoute>
              <Forgot_Pass3 />
            </AuthRoute>
          }
        />
         
        {/* 
<Route path="/socket-page" element={<Socket socket={socket} />}></Route>
<Route path="/chat" element={<Chat socket={socket} />}></Route> */}
        {/* <Route
          path="/socket-page"
          element={
            <AuthRoute>
              <Socket />
            </AuthRoute>
          }
        />
           <Route
          path="/chat"
          element={
            <AuthRoute>
              <Chat/>
            </AuthRoute>
          }
        /> */}
        <Route
          path="/signup-page"
          element={
            <AuthRoute>
              <SignUp />
            </AuthRoute>
          }
        />
        <Route
          path="/update-profile"
          element={
            <AuthRoute>
              <UpdateProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/add-post-page"
          element={
            <ProtectedRoute>
              <AddPost />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-page/:id"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/history-page"
          element={
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/model-page" element={<Model />} />
        {/* <Route path="/profile-page" element={<Profile />} /> */}
        <Route path="/model-profile-page" element={<ModelProfile />} />
        <Route path="/faqs" element={<Faqs />} />
        {/* <Route path="/login-page" element={<Login />} /> */}
        {/* <Route path="/signup-page" element={<SignUp />} /> */}
        {/* <Route path="/add-post-page" element={<AddPost />} /> */}
        <Route path="/packages-page" element={<Packages />} />
        <Route path="/payment-page" element={<Payment />} />
      </Routes>
    </BrowserRouter>

    // <div>Router</div>
  );
};

export default Router;
