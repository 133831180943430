import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import { ALERT_TYPES } from "../../constants/index";
import { ToastContainer, toast } from "react-toastify";
// import { toast } from 'react-toastify';

import Footer from "../../Components/Layout/Footer";
import {
  modelprofileview,
  modelprofillist,
  Getmodelpostlist,
  Getmodelpost,
  UserUnflowmodel,
  profileviewbyidmodel,
  Group_list,
} from "../../api";
import { modelpackagelist, modelpurchaseplane } from "../../api";
import { toastAlert } from "../../utils/index";
import "./style.css";
import Loader from "../../Components/loader";
import { line } from "../../Asserts/images";
import { Cancel } from "../../Asserts/images";
import { useNavigate } from "react-router-dom";

// import {
//   CardElement,
//   useStripe,
//   useElements,
//   Elements,
// } from "@stripe/react-stripe-js";
import "react-toastify/dist/ReactToastify.css";

const notifys = () => toast("Plan Purchase Successfully");

export function UpdateProfile() {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  //   const stripe = useStripe();
  //   const elements = useElements();
  const [isLoading, setLoading] = useState(true);
  const getusertype = localStorage.getItem("userrole");
  const [notify, setNotify] = useState("");

  const [stiprtoken, setstiprtoken] = useState();
  const handlechanges = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  // if (!stripe || !elements) {
  //   return;
  // }

  // const { token, error } = await stripe.createToken(
  //   elements.getElement(CardElement)
  // );

  // if (token) {
  //   setstiprtoken(token?.id);
  //   handleclick(token?.id);
  // } else {
  // }
  //   };

  const planeid = localStorage.getItem("planeid");
  const price = localStorage.getItem("price");
  const id = localStorage.getItem("id ");

  // const notifys = () => toast("Plan Purchase Successfully");

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     const formDataMethod = new FormData();
  //     for (const key in formData) {
  //       formDataMethod.append(key, formData[key]);
  //     }

  //     try {
  //       const response = await modelpurchaseplane(planeid, formDataMethod);

  //       if (response?.status == true) {
  //         localStorage.removeItem("planeid");
  //         localStorage.removeItem("price");
  //         localStorage.removeItem("id ");
  //         setNotify(response?.mgs);

  //         notifys();

  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //         notifys();
  //       } else {
  //         toastAlert(response.statusText, ALERT_TYPES.ERROR);
  //       }
  //     } catch (error) {
  //       console.error("Error in adding model post:", error);
  //       toastAlert(error.message || "An error occurred", ALERT_TYPES.ERROR);
  //     }
  //   };

  const handleSubmit = (event) => {
    event.preventDefault();
        console.log('ss', isDriving)
    if (formData?.profile_pic && isDriving === false ) {
      alert("Please upload the image with the driving license.");
      return; 
    }

    const formDataMethod = new FormData();
    for (const key in formData) {
      formDataMethod.append(key, formData[key]);
    }

    const logoutData = localStorage.getItem("userToken");

    fetch(`${apiUrl}/public/api/user/profile-add-edit`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${logoutData}`,
      },
      body: formDataMethod,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("datas", data.data);
        // document.querySelector('.loaderBox').classList.add("d-none");

        if (data?.status === true) {
          // navigate("/")
          // setLoading(true)
          // modelprofile();
          navigate('/user-profile')
        } else {
          // Handle other responses
        }
      })
      .catch((error) => {
        // document.querySelector(".loaderBox").classList.add("d-none");
        console.error("Error:", error);
      });
  };

  const [Userdata, setUserdata] = useState({});
  const modelprofile = async () => {
    try {
      const response = await modelprofileview();
      console.log("response1", response);

      setFormData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in logging in:", error);
    }
  };

  useEffect(() => {
    modelprofile();
  }, []);

  const [isDriving, setIsDriving] = useState(false);

  const handlefile = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if(name == 'driving_pic') {
        const FileName = file;
        if (FileName) {
            setIsDriving(true);
        } else {
            setIsDriving(false);
        }
    }
    if (file) {
      const FileName = file;
      setFormData((prev) => ({
        ...prev,
        [name]: FileName,
      }));
    }
  };

  const apiUrl = process.env.REACT_APP_BASE_URL;
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  console.log("formData", formData);
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header />
          <section className="payment">
            <div class="container">
              <div class="row">
                <div class="col-md-10 mx-auto">
                  <h5 className="pay">Update Profile</h5>
                  <form
                    className="login-forms  justify-content-center mx-auto  d-flex"
                    onSubmit={handleSubmit}
                  >
                    <div className="row justify-content-center d-flex">
                      <div className="col-md-9">
                        <label className="namin  "> Profile Image </label>
                        <soan className="updateprofile">
                          <input
                            className="nam"
                            type="file"
                            placeholder="Name"
                            // required
                            name="profile_pic"
                            onChange={handlefile}
                          />
                        </soan>
                      </div>

                      <div className="col-md-9 ">
                        <label className="namin">
                          {" "}
                          Upload Image With Driving license{" "}
                        </label>
                        <span className="updateprofile">
                          <input
                            className="nam"
                            type="file"
                            placeholder="Name"
                            // required
                            name="driving_pic"
                            // value={formData}
                            onChange={handlefile}
                          />
                        </span>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> User Name </label>
                        <input
                          disabled
                          className="nam"
                          type="text"
                          value={formData?.name}
                          placeholder="Enter Name"
                          name="name"
                          required
                          onChange={handlechanges}
                        />
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> Email </label>

                        <soan className="updateprofile">
                          <input
                            disabled
                            value={formData?.email}
                            className="nam"
                            type="email"
                            required
                            placeholder="email"
                            name="email"
                            onChange={handlechanges}
                          />
                        </soan>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> facebook </label>

                        <soan className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Facebook Link"
                            name="facebook_link"
                            value={formData?.facebook_link}
                            onChange={handlechanges}
                          />
                        </soan>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> instagram </label>

                        <soan className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter instagram Link"
                            name="instagram_link"
                            value={formData?.instagram_link}
                            onChange={handlechanges}
                          />
                        </soan>
                      </div>

                      <div className="col-md-5">
                        <label className="namin"> youtube </label>

                        <soan className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Youtube Link"
                            name="youtube_link"
                            value={formData?.youtube_link}
                            onChange={handlechanges}
                          />
                        </soan>
                      </div>

                      <div className="col-md-4">
                        <label className="namin"> twitter </label>

                        <soan className="updateprofile">
                          <input
                            className="nam"
                            type="text"
                            // required
                            placeholder="Enter Facebook Link"
                            name="twitter_link"
                            onChange={handlechanges}
                          />
                        </soan>
                      </div>

                      <div className="col-md-9">
                        <label className="namin"> About Us </label>

                        <soan className="updateprofile">
                          <textarea
                            value={formData?.bio}
                            name="bio"
                            placeholder="Enter description"
                            type="text"
                            className="nam"
                            onChange={handlechanges}
                              maxlength="300"
                          >
                            {" "}
                          </textarea>
                        </soan>
                      </div>
                    </div>

                    <div class="slct">
                      <div className="slct-first"></div>
                      <div class="drop">
                        {/* <input type="submit" class="sub" value="CONFIRM"/> */}
                        <button type="submit" class="sub">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <ToastContainer />
        </div>
      )}
    </div>
  );
}

// export default UpdateProfile;
{
  /* if user upload profile_pic must required  driving_pic */
}

// const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Validation: If profile_pic is uploaded, driving_pic must also be uploaded
//     if (formData.profile_pic && !formData.driving_pic) {
//       alert('Please upload the image with the driving license.');
//       return; // Prevent form submission if validation fails
//     }

//     // Prepare payload for submission
//     const payload = new FormData();
//     payload.append('profile_pic', formData.profile_pic);
//     payload.append('driving_pic', formData.driving_pic);

//     try {
//       const response = await ForgetRequest2(payload);
//       if (response?.status === true) {
//         // Handle success
//         navigate("/forget-password2");
//       } else {
//         // Handle error
//       }
//     } catch (error) {
//       console.error('Error in submission:', error);
//       // Handle error
//     }
//   };
